import React,{ useContext,useEffect,useState } from 'react';
import { ComboField } from "jforms-react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { AppContext,LanguageContext,TranslationContext } from '../Contexts';
import type { Review as _Review } from 'jobreviews-common';



const PlacesAutoComplete = ({ setLocalities,initialLocalities }:any) => {
	const t = useContext(TranslationContext);
	const language = useContext(LanguageContext);
	const { googleClient } = useContext(AppContext);
	const [ googleClientIsReady,setGoogleClientIsReady ] = useState(false);

	const setGoogleClientIsReadyTrue = () => setGoogleClientIsReady(true);

	useEffect(() => {
		googleClient.onGoogleApiLoad(setGoogleClientIsReadyTrue); // TODO? cleanup if unmount?
	},[])

	return googleClientIsReady ?
		<PlacesAutoCompletex language={language} setLocalities={setLocalities} ready={googleClientIsReady} initialLocalities={initialLocalities}/>
		:
		<div>{t("Waiting for google places api to load")}</div>
};

const PlacesAutoCompletex = ({ setLocalities,language,initialLocalities }:any) => {	
	const t = useContext(TranslationContext);
	const { setValue,suggestions:{ data },clearSuggestions } = usePlacesAutocomplete({ requestOptions:{ types:[ "locality" ],language } });

	return (
		<ComboField
			name="place"
			label={t("Location")}
			placeHolder={t("No locations selected")}
			onSelectedValueChange={setLocalities}
			onSearchValueChange={setValue}
			suggestions={data}
			itemIdFieldName="place_id"
			itemLabelFieldName="description"
			initialSelection={initialLocalities}
		/>
	);
};



export default PlacesAutoComplete;
