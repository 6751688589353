import React,{ useState,useEffect,useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Form,{ Input,Button,RadioButtons } from "jforms-react";
import { AppContext,AppLayoutStyleContext,TranslationContext,UserContext } from '../Contexts';
import Pagination from './Pagination';
import Review,{ ReviewModerator } from "./Review";
import useSearchState from './searchState';
import PlacesAutoComplete from './PlacesAutoComplete';
import type { Review as _Review,Workplace as _Workplace } from 'jobreviews-common';
import WorkplaceInfo from './WorkplaceInfo';
import { jdebug } from 'jutils';



const JobReviews = () => {
	const { jobReviews } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const user = useContext(UserContext);
	const layoutStyle = useContext(AppLayoutStyleContext)

	const [ sortByChoices,setSortByChoices ] = useState<{ name:string,label:string }[]>([])

	useEffect(() => {
		setSortByChoices([ { name:"newest",label:t("Newest_1")},{ name:"oldest",label:t("Oldest_1")},{ name:"best",label:t("Best_1")},{ name:"worst",label:t("Worst_1")} ]);
	},[ t ])

	const [ ReviewType,setReviewType ] = useState(() => Review);

	useEffect(() => {
		setReviewType(() => user.admin ? ReviewModerator : Review);
	},[ user ])

	// @ts-ignore
	const [ { category,workplaceNameLowerCase,localities,page,itemsInPage,sortBy },search,updateSearch ] = useSearchState();

	// --> results
	const [ workplaces,setWorkplaces ] = useState<_Workplace[]>([]);
	const [ reviews,setReviews ] = useState<_Review[]>([]);
	const [ total,setTotal ] = useState(0);
	// <-- results

	const onSearch = (workplaceName:string,localities:any[],category:string) => {
		jdebug.message("--> onSearch",workplaceName);
		// @ts-ignore
		updateSearch({ workplaceName,localities,category });
		jdebug.message("<-- onSearch");
	};

	const onSlice = (page:number,itemsInPage:number) => {
		jdebug.message("--> onSlice");
		// @ts-ignore
		updateSearch({ page,itemsInPage });
		jdebug.message("<-- onSlice");
	};

	const _updateReviews = async() => {
		jdebug.message("--> JobReviews _updateReviews");
		const query = {
			workplaceNameLowerCase,
			localities,
			sortBy,
			first:page*itemsInPage,
			last:(page+1)*itemsInPage,
		};
		const { reviews,total } = await jobReviews.searchReviews(query);
		setWorkplaces([]);
		setReviews(reviews);
		setTotal(total);
		jdebug.message("<-- JobReviews _updateReviews");
	};

	const _updateWorkplaces = async() => {
		jdebug.message("--> _updateWorkplaces");
		const query = {
			workplaceNameLowerCase,
			localities,
			sortBy,
			first:page*itemsInPage,
			last:(page+1)*itemsInPage,
		};
		const { workplaces,total } = await jobReviews.searchWorkplaces(query);
		setWorkplaces(workplaces);
		setReviews([]);
		setTotal(total);
		jdebug.message("<-- _updateWorkplaces");
	};

	useEffect(() => {
		category === "reviews" ?
			_updateReviews()
			:
			_updateWorkplaces();
	},[ search ]);

	const pagination = (
		<Pagination
			pages={Math.ceil(total/itemsInPage)}
			page={page}
			setPage={(page:number) => onSlice(page,itemsInPage)}
			itemsInPage={itemsInPage}
			setItemsInPage={(itemsInPage:number) => onSlice(page,itemsInPage)}
			itemsInPageChoices={itemsInPageChoices}
			sortBy={sortBy}
			// @ts-ignore
			setSortBy={(sortBy:string) => updateSearch({ sortBy })}
			sortByChoices={sortByChoices}
			minimal={layoutStyle !== "large"}
			t={t}
		/>
	);

	return (
		<div>
			<NavLink to="/app/newreview">{t("Add new review")}</NavLink>
			<div>
				<JobSearch onSearch={onSearch} search={search}/>
			</div>
			<div>
				{t("Total")}: {total} {t("results_1")}
			</div>
			{pagination}
			<div>
				{reviews.map((review:_Review) => <ReviewType key={review._id} review={review} />)}
				{workplaces.map((workplace) => <WorkplaceInfo key={workplace._id} workplace={workplace}/>)}
			</div>
			{pagination}
		</div>
	);
};

const itemsInPageChoices = [ 10,50,100 ];

type JobSearchPT = {
	onSearch:Function;
	search:any;
}

const JobSearch = ({ onSearch,search }:JobSearchPT) => {
	const t = useContext(TranslationContext);
	const [ category,setCategory ] = useState("reviews");
	const [ workplaceName,setWorkplaceName ] = useState("");
	const [ localities,setLocalities ] = useState<any[]>([]);
	const [ searchForChoices,setSearchForChoices ] = useState<any[]>([]);

	useEffect(() => {
		jdebug.message("--> JobSearch useEffect[]");
		console.log(search.localities)
		setCategory(search.category);
		setWorkplaceName(search.workplaceName);
		setLocalities(search.localities);
		jdebug.message("<-- JobSearch useEffect[]");
	},[]);

	useEffect(() => {
		setSearchForChoices([
			{ value:"reviews",label:t("Reviews_1") },
			{ value:"workplaces",label:t("Workplaces_1") },
		]);
	},[ t ]);

	const fetchReviews = async() => {
		onSearch(workplaceName,localities,category);
	};

	return (
		<Form onSubmit={fetchReviews}>
			<RadioButtons name="category" label={t("Search for")} choices={searchForChoices} initialChoice={category} onChange={(value:string) => setCategory(value)}/>
			<Input label={t("Workplace")} name="workplace" onChange={setWorkplaceName} value={workplaceName}/>
			<PlacesAutoComplete initialLocalities={localities} setLocalities={setLocalities} />
			<Button>{t("Search")}</Button>
		</Form>
	);
};



export default JobReviews;
