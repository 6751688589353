import React,{ useContext,useEffect,useState } from 'react';
import { Input,Suggestions,Suggestion } from "jforms-react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { AppContext,LanguageContext,TranslationContext } from '../Contexts';



const PlaceAutoComplete = ({ setSelected }:any) => {
	const language = useContext(LanguageContext);
	const { googleClient } = useContext(AppContext);
	const [ googleClientIsReady,setGoogleClientIsReady ] = useState(false);

	const setGoogleClientIsReadyTrue = () => setGoogleClientIsReady(true);

	useEffect(() => {
		googleClient.onGoogleApiLoad(setGoogleClientIsReadyTrue); // TODO? cleanup if unmount?
	},[])

	return <PlaceAutoCompletex language={language} ready={googleClientIsReady} setSelected={setSelected}/>
};

const PlaceAutoCompletex = ({ language,ready,setSelected }:any) => {	
	const t = useContext(TranslationContext);
	const { ready:usePlacesAutocompleteIsReady,setValue,value,suggestions,clearSuggestions } = usePlacesAutocomplete({ requestOptions:{ types:[ "locality" ],language } });

	return ready ?
		<PlaceAutoCompletexx language={language} setValue={setValue} suggestions={suggestions} ready={true} value={value} setSelected={setSelected}/>
		:
		<div>{t("Waiting for google places api to load")}</div>
};

const PlaceAutoCompletexx = ({ ready,setValue,suggestions:{ status,data },setSelected,value }:any) => {
	const t = useContext(TranslationContext);
	
	const _setValue = (value:string) => {
		setValue(value);
		setSelected(null);
	};

	const _setSelected = (item:any) => {
		setSelected(item);
		setValue(item.description);
	}

	return (
		<>
			<Input label={t("Location")} name="city" onChange={_setValue} value={value}/>
			<Suggestions>
				{status === "OK" && data.map((item:any) => (
					<Suggestion key={item.place_id} item={item} value={item.description} onSelect={_setSelected}/>
				))}
			</Suggestions>
		</>
	);
};



export default PlaceAutoComplete;
