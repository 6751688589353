import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';

import App from "./app/App";
import JobReviewsClient from './client/jobreviews-client';



const googleMapsApiKey = "AIzaSyAwlvU0ztQrIhz4uhvZJtQoGmM9au1Twi0";
const googleUsersId = "426363859578-1oukb5ddn3t010sfrtfo0qqpu17abvu5";
const reCaptchaId = "6LfcFy0oAAAAAICFczTlCHVfVEjqBu-1-sCabUZl";

/*
const cognito = {
	"REGION" : "us-east-1",
	"USER_POOL_ID" : "us-east-1_6onCoETSM",
	"USER_POOL_APP_CLIENT_ID" : "7jcu4hdejbkr5g2di6ktibk6bd"
};
*/

const jobReviewsClient = new JobReviewsClient({
	apiPath : "api",
	googleUsersId,
	googleMapsApiKey,
	reCaptchaId,
	//cognito,
});
jobReviewsClient.init();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App jobReviewsClient={jobReviewsClient}/>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
