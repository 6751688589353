import React,{ useContext } from "react";
import { NavLink } from "react-router-dom";
import MiniSearch from "./MiniSearch";
import { LanguageContext, TranslationContext } from "../Contexts";
//import Auth from "./Auth";



const Home = () => {
	const t = useContext(TranslationContext);
	const language = useContext(LanguageContext);

	const infos:{ [key:string]:any } = {
		en : <HomeEn />,
		fi : <HomeFi />,
	}

	return (
		<div>
			<MiniSearch />
			{infos[language]}
			<NavLink to="/app/terms">{t("Terms, conditions, and privacy policy")}</NavLink>
			{/*<Auth />*/}
		</div>
	);
};

const HomeEn = ()  => (
	<>
		<p>
			Did you know that bad employment decision can set you back years on your career? That's why we are here to help. Avoid employers that could set you back financially and destroy your mental and even physical health. This site contain also positive warnings that you can use to get ahead with your working life goals.
		</p>
		<h1>Rules of the service</h1>
		<p>
			By using this service you have agreed to the terms and conditions.
		</p>
		<p>
			The sole purpose of this service is to help job seekers to find good and better jobs and of course to avoid bad employers. It is not meant for badmouthing employers but giving detailed and objective information for job seekers. If you have actual bad experiences like consistently being treated badly by management, not receiving correct salary, safety violations etc... be vocal about it. Try to be as precise and descriptive as you can without being identifiable (if you care about that). Tell us about good employers too. That will help good workplaces to get good employees. Sometimes previously bad workplace becomes good e.g. when management is changed. Tell us if you observed that a previously bad workplace has turned itself around and is nowadays a good place to work. Unfortunately it is also really common for a good workplace to be destroyed by new incompetent management and it is important to report these changes here that future job seekers know to avoid those places.
		</p>
		<p>
			Always take everything that is said here with a grain of salt. Some bad reviews might be hate reviews from people who got terminated for good reason. It is also possible for companies to write false reviews about themselves. However, if a workplace/company only gets bad reviews it probably is bad employer. Use information you get from here as guidance during your job search and when applying to a specific job. Seek information from elsewhere too and keep your senses sharp during interview process. A bad employment decision can set you back several years on your career.
		</p>
		<p>
			Publishing names or initials, phone numbers, addresses or any other personal or sensitive information, or corporate secrets (especially those protected by law) is strictly prohibited.
		</p>
	</>
);

const HomeFi = ()  => (
	<>
		<p>
			Tiesithän, että huono työnanataja voi hidastaa urakehitystäsi jopa usean vuoden verran? Siksi kannattaa käyttää tätä palvelua. Vältä työnantajat, jotka ovat vahingoksi taloudellesi, mielenterveydellesi ja pahimmassa tapauksessa, fyysiselle terveydellesi. Täältä voit etsiä tietoa myös hyvistä työnanatajista ja tehdä valintoja, jotka antavat sinulle jopa epäreilun edun elämässä.
		</p>
		<h1>Palvelun säännöt</h1>
		<p>
			Käyttämällä tätä palvelua hyväksyt seuraavat ehdot
		</p>
		<p>
			Palvelun ainoa tarkoitus on auttaa työnhakijoita löytämään hyviä ja parempia työpaikkaoja ja välttämään huonoja ja vahingollisia työnantajia. Työnantajien mustamaalaaminen ja muu perusteettomien väitteiden levittäminen on kiellettyä. Ole arvioissasi objektiivinen ja niin yksityiskohtainen kuin mahdollista kuitenkin niin, että sinua ei pysty arviosta yksilöimään, jos sillä on sinulle väliä. Kerro mm. työntekijöiden huonosta kohtelusta, palkanmaksun ongelmista ja työturvallisuusongelmista, jotta muut voivat tehdä harkittuja päätöksiä töitä hakiessaan. Muutokset johtoportaassa voivat hetkessä tuhota hyvän työpaikan tai merkittävästi parantaa huonon työpaikan ilmapiiriä. Jos sinulla on huonoja kokemuksia aiemmin hyvänä pidetystä työpaikasta, kerro siitä täällä. Samoin anna positiivista palautetta, jos aiemmin huono työpaikka on muuttunut paremmaksi, jotta hyvä muutos palkitaan ja hyvät työntekijät löytävät uuteen, hyvään työpaikkaan.
		</p>
		<p>
			Suhtaudu kaikkeen täällä sanottuun terveellä kriittisyydellä. Jotkin huonot arvostelut voivat olla peräisin esimerkiksi ihmisiltä, joiden työsuhde on päätetty hyvästä syystä. Myös yritykset voivat kirjoittaa itsestään valearvosteluja itsestään. Hanki tietoa myös muista lähteistä ja kysy tarkentavia kysymyksiä haastattelun aikana saadaksesi selville, onko työpaikka juuri sinulle sopiva.
		</p>
		<p>
			Minkään henkilötietojen, puhelinnumeroihen, osoitteiden tai muun arkaluonteisen tiedon levittäminen on kiellettyä ja sillä voi olla laillisia seuraamuksia. Liikesalaisuuksien julkaiseminen on myös kiellettyä.
		</p>
	</>
);



export default Home;
