
const ReviewAdded = () => {
	return (
		<div>Your review has been added</div>
	);
}



export default ReviewAdded;
