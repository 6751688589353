import React,{ useContext } from 'react';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { Login,Register,Activate,ResetPassword,NewPassword,VerifyEmail,UserManagement } from "juser-react";
import { AppContext,TranslationContext } from '../Contexts';
import TermsAndconditionsAndPrivacyPolicy from '../legal/TermsAndConditionsAndPrivacyPolicy';



const RegisterPage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const navigate = useNavigate();

	return (
		<Register
			jUserClient={jUserClient}
			translate={t}
			onSuccess={() => navigate(-1)}
			terms={<TermsAndconditionsAndPrivacyPolicy />}
		/>
	);
};

const LoginPage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const navigate = useNavigate();

	return (
		<Login
			jUserClient={jUserClient}
			translate={t}
			onSuccess={() => navigate(-1)}
			onForgottenPassword={() =>  navigate("/app/resetpassword")} />
	);
};

const ResetPasswordPage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const navigate = useNavigate();

	return (
		<ResetPassword
			jUserClient={jUserClient}
			translate={t}
			onSuccess={() => navigate(-1)}
		/>
	);
};

const NewPasswordPage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const [ searchParams,setSearchParams ] = useSearchParams();

	return (
		<NewPassword
			jUserClient={jUserClient}
			translate={t}
			resetKey={searchParams.get("resetkey") || ""}
		/>
	);
};

const ActivatePage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const [ searchParams,setSearchParams ] = useSearchParams();

	return (
		<Activate
			jUserClient={jUserClient}
			translate={t}
			activationKey={searchParams.get("activationkey") || ""}
		/>
	);
};

const VerifyEmailPage = () => {
	const { jUserClient } = useContext(AppContext);
	const t = useContext(TranslationContext);
	const [ searchParams,setSearchParams ] = useSearchParams();

	return (
		<VerifyEmail
			jUserClient={jUserClient}
			translate={t}
			verificationKey={searchParams.get("verificationkey") || ""}
		/>
	);
};



export {
	RegisterPage,
	LoginPage,
	ResetPasswordPage,
	NewPasswordPage,
	ActivatePage,
	VerifyEmailPage,
	UserManagement,
};

/**

 */